.gogo-vehicle-card {
  @apply grid rounded-md bg-white;
  grid-template-columns: 100%;
  grid-auto-rows: auto;

  .frame {
    @apply relative grid box-border;
    grid-template-columns: repeat(2, 50%);
    grid-auto-rows: minmax(min-content, max-content);
    grid-auto-flow: row;

    .pane {
      @apply relative;
    }

    &.top-frame {
      @apply aspect-[16/9];
      .vehicle-image {
        @apply absolute top-0 left-0 block w-full aspect-[16/9];
        background: linear-gradient(to top, #edf5f8 0%, white 32%, white 100%);
        border-radius: 6px 6px 0 0;

        img {
          @apply object-cover;
        }
      }

      .featured {
        @apply absolute flex items-center top-5 ltr:left-0 rtl:right-0 min-w-[7.5rem] h-[1.625rem] text-center font-bold uppercase whitespace-nowrap text-base ltr:rounded-r-[0.25rem] rtl:rounded-l-[0.25rem] text-white;
      }

      .share-icons {
        @apply absolute top-[0.688rem] ltr:right-0 rtl:left-0 min-w-[5rem] px-[5px] text-center whitespace-nowrap text-sm text-white;
      }
    }
  }
}
