.gogo-footer {
  // @apply aspect-[96/25] bg-primary;
  @apply bg-[#F0F0F0];

  .footer-text,
  .social-icon {
    @apply text-white;
  }

  .separator {
    @apply border-0 border-b w-full border-white;
  }

  .social-icon {
    @apply h-8 w-8 2xl:h-[40px] 2xl:w-[40px] 3xl:h-[48px] 3xl:w-[48px];
  }

  ::marker {
    color: #d9d9d9;
  }
}
.gogo-container.mobile-container {
  @media (max-width: 768px) {
    padding-right: 16px !important;
  }
}
