// @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* merriweather-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/merriweather-sans/merriweather-sans-v26-latin-regular.woff2')
      format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('/fonts/merriweather-sans/merriweather-sans-v26-latin-regular.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url('/fonts/merriweather-sans/merriweather-sans-v26-latin-regular.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('/fonts/merriweather-sans/merriweather-sans-v26-latin-regular.svg#MerriweatherSans')
      format('svg'); /* Legacy iOS */
}

/* vazirmatn-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Vazirmatn';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/vazirmatn/vazirmatn-v13-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('/fonts/vazirmatn/vazirmatn-v13-latin-regular.woff') format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url('/fonts/vazirmatn/vazirmatn-v13-latin-regular.ttf') format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('/fonts/vazirmatn/vazirmatn-v13-latin-regular.svg#Vazirmatn')
      format('svg'); /* Legacy iOS */
}

/* outfit-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/outfit/outfit-v11-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('/fonts/outfit/outfit-v11-latin-regular.woff') format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url('/fonts/outfit/outfit-v11-latin-regular.ttf') format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('/fonts/outfit/outfit-v11-latin-regular.svg#Outfit') format('svg'); /* Legacy iOS */
}
@font-face {
  font-display: swap;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/outfit/outfit-medium.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/outfit/outfit-semibold.ttf') format('truetype');
}

$font-family-default-en: 'Merriweather Sans', sans-serif;
$font-family-default-ar: 'Vazirmatn', sans-serif;

$font-family-v2-en: 'Outfit', sans-serif;
$font-family-v2-ar: 'Outfit', sans-serif;
