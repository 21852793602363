.rc-slider {
  .rc-slider-handle {
    @apply shadow-2xl bg-white border-[0.1875rem] border-primary #{!important};
    box-shadow: rgb(0 0 0 / 24%) 1px 1px 2px 0px;
    height: 1.375rem !important;
    width: 1.375rem !important;
    margin-left: 0.625rem !important;
    margin-top: -0.5625rem !important;
    opacity: 1 !important;

    &.rc-slider-handle-1 {
      margin-left: 0.625rem !important;
    }

    &.rc-slider-handle-2 {
      margin-left: -0.625rem !important;
    }

    &.rc-slider-handle-dragging {
      box-shadow: none !important;
    }
  }

  .rc-slider-rail {
    @apply bg-light-gray h-1 #{!important};
  }

  .rc-slider-track {
    @apply bg-primary;
    height: 0.25rem !important;
  }
}

.gogo-home-page {
  .rc-slider .rc-slider-rail {
    background-color: #e0e0e0 !important;
    height: 2px !important;
  }
  .rc-slider .rc-slider-track {
    background-color: #545454 !important;
    height: 2px !important;
  }
  .rc-slider-handle {
    box-shadow: 0px 2px 6.2px 0px rgba(0, 0, 0, 0.25) !important;
    border-color: #212121 !important;
    height: 28px !important;
    width: 28px !important;
    margin-top: -0.8825rem !important;
  }

  .rc-slider .rc-slider-handle.rc-slider-handle-dragging {
    // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16) !important;
    border-color: #212121 !important;
  }

  .additional-expenses-modal {
    .rc-slider-handle {
      // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16) !important;
      border-color: #212121 !important;
      height: 20px !important;
      width: 20px !important;
      margin-top: -0.5625rem !important;
    }

    @media (max-width: 768px) {
      .rc-slider-handle {
        margin-top: -0.8825rem !important;
      }
    }
  }

  @media (max-width: 1024px) {
    .rc-slider-handle {
      margin-top: -1.1rem !important;
    }
  }
}

.rc-slider-v2 {
  .rc-slider {
    .rc-slider-handle {
      @apply shadow-2xl bg-white border-[1px] border-[#212121] #{!important};
      box-shadow: rgb(0 0 0 / 24%) 1px 1px 2px 0px;
      height: 24px !important;
      width: 24px !important;
      margin-left: 0.625rem !important;
      margin-top: -11px !important;
      opacity: 1 !important;

      &.rc-slider-handle-1 {
        margin-left: 0.625rem !important;
      }

      &.rc-slider-handle-2 {
        margin-left: -0.625rem !important;
      }

      &.rc-slider-handle-dragging {
        box-shadow: none !important;
      }
    }

    .rc-slider-rail {
      @apply bg-[#E0E0E0] h-[2px] #{!important};
    }

    .rc-slider-track {
      @apply bg-[#212121];
      height: 2px !important;
    }
  }
}

.range-slider-v2-track.range-slider-v2-track-0 {
  position: relative;
  background: #ddd;
}

.range-slider-v2-track.range-slider-v2-track-1 {
  background: #212121;
  height: 2px;
}

.range-slider-v2-thumb-active {
  border: 2px solid #212121;
  outline: none;
}

.emi-slider-v2-track {
  background: #212121;
  height: 2px;
}

.emi-slider-v2-track.emi-slider-v2-track-1 {
  background: #ddd;
  height: 2px;
}

.emi-slider-v2-thumb-active {
  border: 2px solid #212121;
  outline: none;
}

.vertical-slider {
  height: 206px;
  // width: 2px;
  .vertical-track {
    // left: 9px;
    @apply ltr:left-[9px] rtl:right-[9px];
    width: 2px;
    background: #0000001f;
  }
  .vertical-track-0 {
    background: #0000001f;
  }

  .vertical-track-1 {
    background: #212121;
  }
}
