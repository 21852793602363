// .swiper {
//   height: 35rem;
//   width: 96rem;
//   overflow-x: hidden;
// }
.external-buttons {
  overflow: visible;

  .swiper-button-prev,
  &.swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
    color: white;
  }

  .swiper-button-next,
  &.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
    color: white;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  padding: 25px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-size: 30px;
}

.swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
    color: white;
  }
}

.swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
    color: white;
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled,
.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.gogo-home-page {
  .swiper-shadow {
    width: 62%;
    background: linear-gradient(
      54.17deg,
      #1b313f 0.71%,
      rgba(0, 0, 0, 0) 82.92%
    );
    top: 0;
    bottom: 0;
    left: 0;
  }

  @media (min-width: 1024px) {
    .custom-pagination {
      @apply ltr:left-48 rtl:right-48 absolute top-10 lg:top-[20rem];
    }
    .swiper-pagination {
      display: grid !important;
      justify-content: space-between !important;
      gap: 1.2rem !important;
      left: 6.5rem;
    }

    .swiper-pagination-bullet {
      border-radius: 1.8rem !important;
      background-color: #ffffff70 !important;
      position: relative !important;
      justify-self: center !important;
      align-self: center !important;
      width: 1.875rem !important;
      height: 0.438rem !important;
      overflow: hidden !important;
      transform: rotate(90deg) !important;
      margin: 10px 0 !important;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .swiper-pagination-bullet {
      background: gray !important;
      opacity: 1 !important;
    }

    .swiper-pagination-bullet-active {
      background: white;
      opacity: 1 !important;
      &::before {
        background-color: white;
        animation: slide-progress 2s cubic-bezier(0.3, 0, 0.3, 1) forwards;
        .swiper-paused & {
          animation-play-state: paused;
        }
      }
    }

    .swiper-pagination-bullet-visited {
      background-color: white !important;
      opacity: 1 !important;
    }

    @keyframes slide-progress {
      0% {
        transform: translateX(-100%);
      }

      100% {
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 1024px) {
    .swiper-pagination-bullet {
      background: #ffff !important;
      opacity: 0.5 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: #e02e01 !important;
      opacity: 1 !important;
    }
  }
}

.offer-thank-you-swiper {
  .swiper-slide {
    background: #4c0055;
    opacity: 0.7;
    border-radius: 16px;
  }

  .swiper-slide-active {
    opacity: 1;
  }
}

.offers-swiper-pagination {
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }
  .swiper-pagination-bullet {
    background-color: #ffff;
    opacity: 0.54;
  }
  .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    opacity: 1;
    background-color: #ffff !important;
  }
}

@media (max-width: 1024px) {
  .offers-swiper-pagination {
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
    }
    .swiper-pagination-bullet {
      background-color: #ffff;
      opacity: 0.54;
    }
    .swiper-pagination-bullet-active {
      width: 8px;
      height: 8px;
      opacity: 1;
      background-color: #ffff !important;
    }
  }
}
