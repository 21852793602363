.gogo-home-page {
  @apply w-full;

  .sticky-search {
    .sticky-search-wrapper {
      @apply w-full;
      padding: 64px 148px;
    }
  }

  .blade-outer-container {
    @apply w-full px-8 lg:px-[80px] xl:px-[120px] 2xl:px-[142px] 3xl:px-[180px];

    &.need-help-container {
      @apply p-0 lg:px-[80px] lg:pt-[5.5rem] lg:pb-[5.6612rem] xl:px-[120px] 2xl:px-[142px] 3xl:px-[180px];
    }
  }

  .need-help-for-search-wrapper,
  .trending-search,
  .search-wrapper {
    @apply bg-light-gray;
  }

  .search-text {
    @apply text-primary;
  }

  .affordable-filter {
    @apply flex flex-col justify-center gap-[0.9375rem] items-center rounded-md;
  }

  .trending-vehicle-img {
    box-shadow: 0px 0px 5px 2px rgba(88, 99, 106, 0.08);
    picture {
      @apply w-[120px] 2xl:w-[146px] 3xl:w-[156px];
    }
  }

  .trending-vehicle-img:hover {
    box-shadow: 0px 0px 5px 2px rgba(88, 99, 106, 0.1);
    picture {
      @apply w-[125px] 2xl:w-[150px] 3xl:w-[160px];
    }
  }

  .need-help {
    @apply bg-primary;
  }

  .search-area {
    .search-form {
      input {
        &::placeholder {
          @apply text-dark-gray1;
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          @apply text-dark-gray1;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          @apply text-dark-gray1;
        }
      }
    }
  }

  .search-icon {
    @apply w-[1.875rem] h-[1.875rem] ltr:ml-[16px] ltr:mr-[5px] lg:ltr:ml-[16px] lg:ltr:mr-[5px] xl:ltr:ml-[20px] xl:ltr:mr-[10px] 2xl:ltr:ml-[25px] 2xl:ltr:mr-[12px] 3xl:ltr:ml-[30px] 3xl:ltr:mr-[15px];
    @apply rtl:mr-[16px] rtl:ml-[5px] lg:rtl:mr-[16px] lg:rtl:ml-[5px] xl:rtl:mr-[20px] xl:rtl:ml-[10px] 2xl:rtl:mr-[25px] 2xl:rtl:ml-[12px] 3xl:rtl:mr-[30px] 3xl:rtl:ml-[15px];
  }

  .buy-a-car,
  .sell-a-car {
    @apply w-[280px] 3xl:w-[357px] aspect-[119/103] rounded-[2px];
  }

  .gogo-container.mobile-container {
    @media (max-width: 768px) {
      padding-right: 16px !important;
    }
  }
}

html:dir(ltr) {
  .gogo-home-page {
    // .container {
    //   @media (max-width: 640px) {
    //     padding-right: 20px !important;
    //   }
    // }
  }
}

html:dir(rtl) {
  .gogo-home-page {
    // .container {
    //   @media (max-width: 640px) {
    //     padding-right: 0 !important;
    //     padding-left: 0 !important;
    //   }
    // }
    .image-transform {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.gogo-price-range {
  .gogo-dropdown {
    width: 200px !important;
    .select-area {
      border-radius: 0 !important;
      border-color: transparent !important;
      background-color: white !important;
      justify-content: unset !important;
      // margin-left: 20px !important;
      // margin-right: 20px !important;
      // width: 215px !important;
      span {
        font-size: 15px !important;
        color: #8f90a6 !important ;
        font-weight: 400 !important;
      }
    }
    .dropdown-search-widget {
      margin-top: 32px !important;
      min-width: 293px !important;
      margin-left: -18px !important;
      border-radius: 32px !important;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .dropdown-item {
      font-size: 18px !important;
      color: #212121 !important ;
      font-weight: 500 !important;
      line-height: 25.2px;
      padding: 0.5rem !important;
    }
    .dropdown-item:hover {
      cursor: pointer;
      --tw-bg-opacity: 1;
      background-color: white;
      --tw-text-opacity: 1;
      color: #212121 !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      --tw-bg-opacity: 1;
      background-color: #68266e !important;
      opacity: 0 !important;
    }

    // ::-webkit-scrollbar-track {
    //   border-radius: 10px;
    //   background-color: red !important;
    // }
  }
}

// .gogo-container {
//   max-width: 1152px;
//   margin-left: auto;
//   margin-right: auto;
//   width: 90%;
// }

.gogo-container {
  // @apply px-[20px] lg:px-[6.25rem] max-w-full;
  @apply px-[0] pl-[16px] md:px-[20px] mx-auto lg:px-[0] lg:max-w-[1152px] max-w-full;
}

@media only screen and (max-width: 1023px) {
  .gogo-container {
    @apply ltr:pl-[16px] ltr:pr-[0px];
    // @apply rtl:pr-[16px] rtl:pl-[0px];
  }
}

@media only screen and (max-width: 1200px) {
  .gogo-container {
    @apply pl-[16px] pr-[16px];
  }
}
