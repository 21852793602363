input,
textarea,
select,
.react-tel-input input,
.rmdp-container input {
  @apply w-full px-3 py-2 border leading-tight resize-none rounded-none text-dark-gray1 border-light-gray h-12 text-xl;
  outline: none !important;
  border-radius: 4px;

  &[aria-invalid='true'] {
    @apply border border-error;
  }

  &:disabled {
    @apply text-dark-gray1 bg-light-gray;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    // @apply text-gray-gray2;
    @apply text-gray-100;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    // @apply text-dark-gray2;
    @apply text-gray-100;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    // @apply text-dark-gray2;
    @apply text-gray-100;
  }

  &[type='checkbox'],
  &[type='radio'] {
    @apply w-6 h-6 bg-gray-100 border-gray-300 accent-primary;
  }
}

[aria-invalid='true'] {
  .react-tel-input input,
  .rmdp-container input {
    @apply border border-error;
  }
}

.react-tel-input input {
  @apply rounded-l-none;
}
.phone-input-v1 {
  .react-tel-input input {
    @apply rounded-l-[4px];
  }
}

.phone-direction {
  direction: ltr !important;
}

.unit-container {
  @apply text-white bg-dark-gray2;
}

.rmdp-container {
  width: 100% !important;
  .rmdp-input {
    @apply h-12 text-xl m-0;
  }
}

.form,
form {
  label {
    @apply text-base font-bold text-dark-gray1 pb-[0.375rem];
  }

  p.error {
    @apply text-sm font-bold text-error;
  }
}

.go-form-dropdown {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-[4px];
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .placeholder {
    // @apply text-dark-gray2;
    @apply text-gray-100;
  }

  .dropdown-item {
    @apply text-xl flex items-center px-2 py-3 hover:bg-primary hover:text-white active:bg-dark-purple active:text-white;
  }

  .select-area {
    @apply flex items-center justify-between px-3 py-2 focus:outline-none w-full border rounded-none text-dark-gray1 border-light-gray bg-white h-12 text-xl;
    border-radius: 4px;
    &[aria-invalid='true'] {
      @apply border border-error;
    }
  }
}

/* File Upload */
.file-area {
  @apply w-full relative;

  input[type='file'] {
    @apply absolute w-full h-full top-0 left-0 right-0 bottom-0 opacity-0;
  }

  .file-dummy {
    @apply w-full text-center bg-gray-100 border-[2px] border-dashed border-gray-300;
  }

  &:hover .file-dummy {
    @apply text-sm bg-gray-200 text-gray-500;
  }

  .file-preview {
    @apply w-full bg-gray-100 text-center border-[2px] border-solid border-gray-300;
  }
}
