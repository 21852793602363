.campaign {
  .placeholder {
    @apply flex items-center justify-center w-full aspect-[1820/700] bg-dark-gray2;

    span {
      @apply sm:text-5xl text-2xl text-center sm:text-start font-semibold text-white mx-auto;
    }
  }

  .offer-btn-container {
    @apply flex items-center justify-center;

    button {
      @apply md:my-[5.625rem] my-[3rem] mx-auto py-5 px-6;
    }
  }

  .additional_details {
    @apply flex flex-col gap-[0.875rem] items-center text-dark-gray1 justify-center py-[3.75rem];

    /* Lighter Grey */

    background: #eeeeee;

    .about {
      @apply font-bold text-center md:w-[40rem] text-dark-gray1;
      font-size: 2.5rem;
      line-height: 50px;
    }
  }

  .terms_and_conditions {
    @apply p-10 md:p-24;

    span {
      @apply font-bold text-center text-primary;
      font-size: 24px;
      line-height: 30px;
    }

    ul {
      @apply list-disc px-[2rem] pt-[1.438rem];

      li {
        @apply font-normal p-2 text-dark-gray1;
        font-size: 1.438rem;
        line-height: 29px;
      }
    }
  }
  .inspection_point_ar {
    ul {
      @apply list-disc pl-[10rem] pt-[1.438rem];

      li {
        @apply font-semibold p-2 text-dark-gray1;
        font-size: 1.438rem;
      }
    }
    @media (max-width: 768px) {
      ul {
        @apply pl-0;

        li {
          font-size: 1rem !important;
        }
      }
    }
  }
  .inspection_point_en {
    ul {
      @apply list-disc pl-[1rem] pt-[1.438rem];

      li {
        @apply font-semibold p-2 text-dark-gray1;
        font-size: 1.438rem;
      }
      @media (max-width: 768px) {
        li {
          font-size: 1rem !important;
        }
      }
    }
  }
}

html {
  &[dir='ltr'] {
    .filter-type.active::after {
      content: '';
      border: 2px solid #4c0055;
      width: 0.5px;
      position: absolute;
      right: -2px;
      top: 0;
      height: 28px;
      border-radius: 5px;
    }
  }

  &[dir='rtl'] {
    .filter-type.active::after {
      content: '';
      border: 2px solid #4c0055;
      width: 0.5px;
      position: absolute;
      left: -2px;
      top: 0;
      height: 28px;
      border-radius: 5px;
    }
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1200px) {
  .offer-right-side-content {
    display: none !important;
  }
}

@media only screen and (min-width: 1023px) {
  html {
    &[dir='ltr'] {
      .thank-you-close {
        left: unset !important;
      }
    }
  }
}

.gogo-container.mobile-container-campagins {
  @media (max-width: 768px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.mobile-container-widget {
  @media (max-width: 768px) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media (max-width: 768px) {
  html {
    &[dir='ltr'] {
      .mobile-container-p {
        padding-left: 24px;
      }
    }

    &[dir='rtl'] {
      .mobile-container-p {
        padding-right: 24px;
      }
    }
  }
}
