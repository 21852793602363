@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: #{$fs-font-size}px !important;
      &.heading-hover:hover {
        font-size: #{$fs-font-size + 1}px !important;
      }
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: #{$fs-font-size}px !important;
        &.heading-hover:hover {
          font-size: #{$fs-font-size + 1}px !important;
        }
      }
    }
  }
}
