.header {
  .top-header {
    @apply h-[3.75rem] md:flex items-center hidden bg-white;
    .menu-item {
      @apply font-bold cursor-pointer text-white;
    }
  }
  .main-header {
    // @apply h-[6.75rem] flex items-center;
    @apply h-[3.75rem] flex items-center;
    .menu-item {
      // @apply font-bold uppercase cursor-pointer text-primary;
      @apply text-[15px] font-medium capitalize cursor-pointer text-[#272828];
    }
  }

  .main-header-expandable {
    .menu-item {
      @apply font-bold uppercase cursor-pointer text-primary;
    }
  }

  .header-dropdown {
    .menu {
      box-shadow: 0px 4px 20px rgba(88, 99, 106, 0.75);
    }
  }
  .header-dropdown-item {
    @apply flex items-center py-[7px] px-[15px] cursor-pointer text-dark-gray1 text-lg;

    &:hover {
      @apply bg-primary text-white;
    }
    &:hover .header-dropdown-cart-count {
      @apply bg-white text-dark-gray1;
    }
    &.active {
      @apply bg-dark-purple text-white;
    }
  }

  .header-dropdown-cart-count {
    @apply bg-dark-purple text-white;
  }

  .menu-items {
    @apply min-h-[150px] overflow-auto;
  }
  .name {
    color: #181818;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 200px;
    overflow: hidden;
    overflow-wrap: normal;
    font-size: 13px;
  }

  .notification-anchor-text {
    text-decoration: underline;
    color: #4b124b;
    font-size: 13px;
  }
  .time {
    display: block;
    font-size: 11px;
  }
}

.bell-notification {
  .EmailEditorTheme__paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      white-space: nowrap !important;
    }
  }
}

.header,
.header-sticky {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.header-hide {
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.header-sticky {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: opacity 0.3s ease;
  border-bottom: 1px solid #ebebf0;
}

.lang-linear-gradient {
  background: linear-gradient(
    114.44deg,
    #ffffff 7.73%,
    #fbfdfe 50.35%,
    #f1fcff 92.95%
  ) !important;
}
