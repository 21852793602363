@charset "UTF-8";

@tailwind base;

@layer base {
  img.vehicle {
    @apply aspect-[16/9] object-cover;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
  }
}

@tailwind components;
@tailwind utilities;

@import 'abstracts/variables';
@import 'abstracts/mixin';
@import 'base/base';
@import './components';
@import './pages';

html {
  @apply text-[12px] sm:text-[14px] md:text-[12px] lg:text-[11px] xl:text-[12px] 2xl:text-[15px] 3xl:text-[16px];
  @apply bg-lighter-gray scroll-smooth;
  &[dir='ltr'] {
    body {
      @apply p-0 m-0 bg-white max-w-screen-3xl mx-auto;
      .theme-default {
        @apply text-dark-gray1;
        font-family: $font-family-default-en !important;
      }
      .theme-v1 {
        @apply text-dark-gray4;
        font-family: $font-family-v2-en !important;
      }
    }
  }
  &[dir='rtl'] {
    body {
      @apply p-0 m-0 bg-white max-w-screen-3xl mx-auto;
      .theme-default {
        @apply text-dark-gray1;
        font-family: $font-family-default-ar !important;
      }
      .theme-v1 {
        @apply text-dark-gray4;
        font-family: $font-family-v2-ar !important;
      }
    }
  }
}

@layer components {
  .header-car-filter {
    @apply flex items-center justify-start overflow-x-auto px-4 gap-1 my-3;
  }

  .header-car-filter-item {
    width: 95px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    min-height: 68px;
    flex: 1 0 auto;
  }

  .header-car-filter-item-img {
    height: 52px;
    @apply flex items-center justify-center flex-col;
  }

  .header-car-filter.active {
    @apply gap-3 mt-2;
  }

  .header-car-filter.active .header-car-filter-item {
    @apply w-auto p-4 flex items-center gap-2 bg-neutral-100 rounded-lg mb-3;
    font-size: 12px;
    font-weight: 400;
    min-height: 42px;
  }

  .header-car-filter.active .header-car-filter-item-img {
    @apply w-[24px] h-auto;
  }

  .header-mobile {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: opacity 0.3s ease;
  }
}

.body-scroll {
  overflow: hidden !important;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  @apply antialiased;
  box-sizing: border-box;
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
}

.header-bar {
  transition: top 0.3s ease-in-out 0s;
}

.min-page-height {
  min-height: calc(100vh - 6.75rem);
  @screen lg {
    min-height: calc(100vh - 10.5rem);
  }
}

.main {
  .main-container {
    @apply w-full px-4 sm:px-[64px] md:px-[84px] lg:px-[148px] py-[32px] h-full bg-white;
  }
}

.container {
  @apply px-[16px] md:px-[20px] lg:px-[9rem] max-w-full;
}

.card-shadow {
  box-shadow: 0px 0px 5px 3px rgba(88, 99, 106, 0.15);
}

.color-border-box {
  position: relative;
  padding: 12px 20px;
  display: inline-block;
}

.color-border-box::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 7px; /* control the border thickness */
  background: conic-gradient(
    from 1deg at 50% 50%,
    #de2a00 0deg,
    rgba(255, 131, 0, 0) 360deg
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.password-input {
  width: 100%;
  outline: none;
  border-radius: 4px;
  border: 1px solid lightgrey;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.btn-group {
  @apply p-2 border border-light-gray rounded-md w-max;

  :nth-child(2) {
    margin: 0 12px;
  }

  .grp-btn {
    @apply p-0 h-12 w-[12.625rem] rounded-none border-none text-primary text-lg font-medium;
    font-style: normal;
    &.active {
      @apply bg-primary border-primary text-white rounded;
    }
  }
}

.card-border {
  border: 1.5px solid #e0e0e0;
}

.shopping-slider {
  @apply flex items-center max-w-[85%] lg:max-w-[93%] md:max-w-[91%] gap-4 px-[0.6rem] overflow-x-scroll scroll-smooth;

  &::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

iframe {
  width: 100%;
  height: 400px;
}

.vsTag {
  background: #24272c;
  border: 1px solid rgba(36, 39, 44, 0.15);
  border-radius: 50%;
  color: #ffffff;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  margin-top: 0;
  position: absolute;
  text-align: center;
  top: 20%;
  width: 40px;
  font-size: 16px;
  z-index: 1;
}

html:dir(ltr) {
  .vsTag {
    right: -27px;
  }
}

html:dir(rtl) {
  .vsTag {
    left: -27px;
  }
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.globalloader {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  background-color: rgba(10, 10, 10, 0.45) !important;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search-widget {
  .error {
    display: none !important;
  }
  button:disabled,
  button[disabled] {
    opacity: 0.5;
  }
}

.bg-gradient-price {
  background: linear-gradient(
    180deg,
    rgba(6, 6, 6, 0) 0%,
    rgba(3, 3, 3, 0.36735) 30.5%,
    rgba(0, 0, 0, 0.79) 100%
  );
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.select-arrow-hide {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.fade-deal {
  mask-image: linear-gradient(
    170.25deg,
    #ffffff 4.09%,
    rgba(255, 255, 255, 0) 94.23%
  );
  -webkit-mask-image: linear-gradient(
    170.25deg,
    #ffffff 4.09%,
    rgba(255, 255, 255, 0) 94.23%
  );
}
