.mobile-scroll {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-[4px];
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .placeholder {
    @apply text-dark-gray2;
  }
}

@media (max-width: 1023px) {
  .gogo-motor-scroll-move::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 1024px) {
  .gogo-motor-scroll-move::-webkit-scrollbar {
    display: block;
  }

  .gogo-motor-scroll-move::-webkit-scrollbar:vertical {
    width: 6px;
  }

  .gogo-motor-scroll-move::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  .gogo-motor-scroll-move::-webkit-scrollbar-thumb {
    @apply bg-[#c1c1c1] rounded-[4px];
  }

  .gogo-motor-scroll-move::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  .lg\:overflow-x-clip {
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }
}
